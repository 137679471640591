




























































































import { Company, Permission, Role } from '@app/models';
import fb from 'firebase/app';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class CompanyRoles extends Vue {
  @Prop({ type: Object })
  public company!: Company;

  @Prop({ type: Boolean })
  public noReset?: boolean;

  @Prop({ type: String })
  public path?: string;

  public get permissions() {
    return Permission.ALL.map(permission => {
      const roles = this.company.roles.reduce((acc, role) => {
        acc[role.name] = role.permissions.indexOf(permission) >= 0;
        return acc;
      }, {} as { [role: string]: boolean });

      return {
        ...roles,
        label: this.$t('permission-' + permission),
        permission,
      };
    });
  }

  public get fields() {
    const permissions = {
      key: 'label',
      label: 'Rechten',
      thClass: 'th-permission',
      tdClass: 'td-permission',
    };

    const roles = this.company.roles.map(r => ({
      key: r.name,
      label: r.name,
      thClass: 'th-role',
      tdClass: 'td-role',
    }));

    return [permissions, ...roles];
  }

  public set(permission: Permission, role: number, value: boolean) {
    this.company.roles[role].permissions = value
      ? [...this.company.roles[role].permissions, permission]
      : this.company.roles[role].permissions.filter(p => p !== permission);
  }

  public async roleAdd() {
    const name = window.prompt(this.$t('name'));
    if (!name || this.company.roles.find(role => role.name === name)) return;

    const role: Role = { name, permissions: [] };
    const roles = fb.firestore.FieldValue.arrayUnion(role);
    await this.updateRoles(roles);
  }

  public async roleRemove(role: Role) {
    const roles = fb.firestore.FieldValue.arrayRemove(role);
    await this.updateRoles(roles);
  }

  public async roleRename(role: Role, index: number) {
    const name = window.prompt(this.$t('name'), role.name);
    if (!name || this.company.roles.find(role => role.name === name)) return;

    const next: Role = { ...role, name };
    const roles = [...this.company.roles];
    roles.splice(index, 1, next);
    await this.updateRoles(roles);
  }

  public async roleReset() {
    this.company.roles = this.$company.appRoles;
  }

  public async roleShiftLeft(role: Role, index: number) {
    const roles = [...this.company.roles];
    roles.splice(index, 1);
    roles.splice(index - 1, 0, role);
    await this.updateRoles(roles);
  }

  public async roleShiftRight(role: Role, index: number) {
    const roles = [...this.company.roles];
    roles.splice(index, 1);
    roles.splice(index + 1, 0, role);
    await this.updateRoles(roles);
  }

  private getPermissions(name: string): Permission[] {
    const role = this.company.roles.find(r => r.name === name);
    return role ? role.permissions : [];
  }

  private async updateRoles(roles: Role[] | fb.firestore.FieldValue) {
    await this.$firebase
      .doc(this.path || `company/${this.company.id}`)
      .set({ roles }, { merge: true });
  }
}
