






import { Company } from '@app/models';
import { debounce } from '@bcase/core';
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';

import CompanyRoles from '../company/company-roles.vue';

@Component({
  components: { CompanyRoles },
})
export default class AdminRoleManagement extends Vue {
  private debounceSave = debounce(this.save.bind(this), 1000);

  public get roles() {
    return this.$company.appRoles;
  }

  @Watch('roles', { deep: true })
  public watchRoles() {
    this.debounceSave(this.roles);
  }

  public async save(roles: Company['roles']) {
    await this.$firebase.doc(`app/roles`).set({ roles }, { merge: true });
    this.$bce.message(this.$t('changes-saved'), 1, 'primary');
  }
}
